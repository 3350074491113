var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "div",
        [
          _c(
            "b-alert",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              staticStyle: { "margin-left": "20px" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "practice-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Search location",
                        },
                        on: {
                          keyup: _vm.debouncePracticeLocations,
                          search: _vm.debouncePracticeLocations,
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-right": "20px" } },
                [
                  _vm.hasPermission("edit")
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.new-practice-location",
                              modifiers: { "new-practice-location": true },
                            },
                          ],
                          staticClass: "avo-primary-btn",
                        },
                        [_vm._v("Add Location")]
                      )
                    : _vm._e(),
                  _c("practice-location-add", {
                    on: {
                      submitted: function ($event) {
                        return _vm.getPracticeLocations()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.locations.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.locations, function (location, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "location-box" },
                      [
                        _c(
                          "div",
                          {
                            style:
                              _vm.selected == index
                                ? "padding-bottom:20px;"
                                : "",
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "avo-text-light",
                                    attrs: { cols: "auto" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectLocation(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" Location ")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "avo-header-text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectLocation(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(location.name) + " ")]
                                ),
                                _vm.hasPermission("edit")
                                  ? _c(
                                      "b-col",
                                      { attrs: { lg: "auto" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName: "v-b-modal",
                                                value:
                                                  "practice-location-edit-" +
                                                  location.location_id,
                                                expression:
                                                  "'practice-location-edit-'+location.location_id",
                                              },
                                            ],
                                            staticClass: "avo-basic-btn",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-edit mr-2",
                                            }),
                                            _vm._v("Edit"),
                                          ]
                                        ),
                                        _c("practice-location-edit", {
                                          attrs: {
                                            "selected-location": location,
                                          },
                                          on: {
                                            submitted: function ($event) {
                                              return _vm.getPracticeLocations()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        index == _vm.selected
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-top": "solid 1px #C9D2DF",
                                  "margin-left": "-20px",
                                  "margin-right": "-40px",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "mt-4",
                                    staticStyle: { "margin-left": "0px" },
                                  },
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" NPI ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  location.group_npi || "-"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" Tax ID ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(location.tax_id || "-") +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" PTAN ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(location.ptan || "-") +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "mt-4",
                                    staticStyle: { "margin-left": "0px" },
                                  },
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" Phone ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  location.telephone || "-"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" Fax ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(location.fax || "-") +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "label-text" },
                                            [_vm._v(" Address ")]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  location.address || "-"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          align: "center",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm.loaded
            ? _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v(" No locations found for this practice "),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }