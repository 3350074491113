var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "b-container",
        { staticClass: "p-0" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.claimStatsLoading } },
                    [
                      _c("b-card", { staticClass: "avo-info-card mb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start",
                          },
                          [
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v("Total Billed Value"),
                            ]),
                            _c("div", { staticClass: "total-amount-text" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    parseFloat(
                                      _vm.claimStats.gross_charges
                                    ).toLocaleString()
                                  )
                              ),
                            ]),
                            _vm.grossChargeDirection != "no-change"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      { class: _vm.grossChargeDirection },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon:
                                              _vm.grossChargeDirection ==
                                              "increase"
                                                ? "caret-up-fill"
                                                : "caret-down-fill",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.grossPercentChange) +
                                            "% "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "avo-text-light-small ml-2",
                                      },
                                      [_vm._v("Prior month")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "avo-text-light-small" },
                                  [_vm._v("Same as prior month")]
                                ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start",
                          },
                          [
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v("Total Patient Count"),
                            ]),
                            _c("div", { staticClass: "total-amount-text" }, [
                              _vm._v(_vm._s(_vm.claimStats.patient_visits)),
                            ]),
                            _vm.patientVisitDirection != "no-change"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      { class: _vm.patientVisitDirection },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon:
                                              _vm.patientVisitDirection ==
                                              "increase"
                                                ? "caret-up-fill"
                                                : "caret-down-fill",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.visitPercentChange) +
                                            "% "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "avo-text-light-small ml-2",
                                      },
                                      [_vm._v("Prior month")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "avo-text-light-small" },
                                  [_vm._v("Same as prior month")]
                                ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start",
                          },
                          [
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v("Total Paid"),
                            ]),
                            _c("div", { staticClass: "total-amount-text" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    parseFloat(
                                      _vm.claimStats.collection
                                    ).toLocaleString()
                                  )
                              ),
                            ]),
                            _vm.collectionDirection != "no-change"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      { class: _vm.collectionDirection },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon:
                                              _vm.collectionDirection ==
                                              "increase"
                                                ? "caret-up-fill"
                                                : "caret-down-fill",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.collectionPercentChange
                                            ) +
                                            "% "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "avo-text-light-small ml-2",
                                      },
                                      [_vm._v("Prior month")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "avo-text-light-small" },
                                  [_vm._v("Same as prior month")]
                                ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start",
                          },
                          [
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v("Total A/R Balance"),
                            ]),
                            _c("div", { staticClass: "total-amount-text" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    parseFloat(_vm.netARValue).toLocaleString()
                                  )
                              ),
                            ]),
                            _vm.netARDirection != "no-change"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      { class: _vm.netARDirection },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon:
                                              _vm.netARDirection == "increase"
                                                ? "caret-up-fill"
                                                : "caret-down-fill",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.netARPercentChange) +
                                            "% "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "avo-text-light-small ml-2",
                                      },
                                      [_vm._v("Prior month")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "avo-text-light-small" },
                                  [_vm._v("Same as prior month")]
                                ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center align-items-start",
                          },
                          [
                            _c("div", { staticClass: "avo-text-light" }, [
                              _vm._v("Total Write-offs"),
                            ]),
                            _c("div", { staticClass: "total-amount-text" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    parseFloat(
                                      _vm.claimStats.write_offs
                                    ).toLocaleString()
                                  )
                              ),
                            ]),
                            _vm.writeOffsDirection != "no-change"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      { class: _vm.writeOffsDirection },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon:
                                              _vm.writeOffsDirection ==
                                              "increase"
                                                ? "caret-up-fill"
                                                : "caret-down-fill",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.writeOffsPercentChange) +
                                            "% "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "avo-text-light-small ml-2",
                                      },
                                      [_vm._v("Prior month")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "avo-text-light-small" },
                                  [_vm._v("Same as prior month")]
                                ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c("b-card", { staticClass: "white-card shadow mb-4" }, [
                    _c("div", { staticClass: "summary-card-header-txt" }, [
                      _vm._v(" Collections by Month "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "chart-container",
                        staticStyle: { height: "350px" },
                      },
                      [
                        _vm.collectionsByMonthLoading
                          ? _c(
                              "div",
                              { staticClass: "spinner-container" },
                              [_c("b-spinner")],
                              1
                            )
                          : _vm._e(),
                        _c("collections-line-chart", {
                          attrs: {
                            chartData: _vm.collectionsByMonthChartData,
                            styles: {
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pr-2" },
                    [
                      _c("div", { staticClass: "summary-card-header-txt" }, [
                        _vm._v(" Claims by Stage "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "chart-container",
                          staticStyle: { height: "400px" },
                        },
                        [
                          _vm.claimsByStageChartLoading
                            ? _c(
                                "div",
                                { staticClass: "spinner-container" },
                                [_c("b-spinner")],
                                1
                              )
                            : _vm._e(),
                          _c("horizontal-bar-chart", {
                            attrs: {
                              chartData: _vm.claimsByStageChartData,
                              styles: {
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("b-card", { staticClass: "white-card shadow mb-4" }, [
                    _c("div", { staticClass: "summary-card-header-txt" }, [
                      _vm._v(" AR by Month "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "chart-container",
                        staticStyle: { height: "350px" },
                      },
                      [
                        _vm.ARByMonthLoading
                          ? _c(
                              "div",
                              { staticClass: "spinner-container" },
                              [_c("b-spinner")],
                              1
                            )
                          : _vm._e(),
                        _c("ar-line-chart", {
                          attrs: {
                            chartData: _vm.ARByMonthChartData,
                            styles: {
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pr-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "summary-card-header-txt mb-2" },
                        [_vm._v(" Top Providers by Encounters ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "chart-container",
                          staticStyle: { height: "350px" },
                        },
                        [
                          _vm.encounterChartLoading
                            ? _c(
                                "div",
                                { staticClass: "spinner-container" },
                                [_c("b-spinner")],
                                1
                              )
                            : _vm._e(),
                          _c("horizontal-bar-chart", {
                            attrs: {
                              chartData: _vm.encounterChartData,
                              styles: {
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pr-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "summary-card-header-txt mb-2" },
                        [_vm._v(" Top Procedures by Count ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "chart-container",
                          staticStyle: { height: "350px" },
                        },
                        [
                          _vm.procedureChartLoading
                            ? _c(
                                "div",
                                { staticClass: "spinner-container" },
                                [_c("b-spinner")],
                                1
                              )
                            : _vm._e(),
                          _c("horizontal-bar-chart", {
                            attrs: {
                              chartData: _vm.procedureChartData,
                              styles: {
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pb-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "summary-card-header-txt mb-4" },
                        [_vm._v(" Accounts Receivable by Age ")]
                      ),
                      _c("donut-with-bar", {
                        attrs: {
                          chartData: _vm.ARDonutChartData,
                          dataLoading: _vm.ARChartLoading,
                          format: "dollars",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-0", attrs: { cols: "4" } },
                [
                  _c("b-card", { staticClass: "summary-card shadow mb-4" }, [
                    _c("div", { staticClass: "summary-card-header-txt" }, [
                      _vm._v(" Top Claim Issues "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "chart-container",
                        staticStyle: { height: "100px" },
                      },
                      [
                        _vm.issuesChartLoading
                          ? _c(
                              "div",
                              { staticClass: "spinner-container" },
                              [_c("b-spinner")],
                              1
                            )
                          : _vm._e(),
                        _c("donut-chart", {
                          attrs: {
                            loaded: !_vm.issuesChartLoading,
                            chartData: _vm.issueChartData,
                            styles: {
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pl-3" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("div", { staticClass: "claims-worked-text mr-5" }, [
                          _vm._v(_vm._s(_vm.claimStats.claims_worked)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "avo-medium-text" }, [
                              _vm._v("Claims Worked"),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pl-3" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("div", { staticClass: "days-ar-text mr-5" }, [
                          _vm._v(_vm._s(_vm.avgDaysInAR)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "avo-medium-text" }, [
                              _vm._v("Average Days in A/R"),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pl-3" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("div", { staticClass: "first-pass-text mr-5" }, [
                          _vm._v(_vm._s(_vm.firstPassPercent) + "%"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "avo-medium-text" }, [
                              _vm._v("First Pass"),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pl-3" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "claims-collected-text mr-5" },
                          [_vm._v(_vm._s(_vm.claimsCollectedPercent) + "%")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "avo-medium-text" }, [
                              _vm._v("Claims Collected"),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("b-card", { staticClass: "summary-card shadow mb-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-start pb-4",
                      },
                      [
                        _c("div", { staticClass: "summary-card-header-txt" }, [
                          _vm._v(" Top Providers by Billing "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center small-head-txt-med mb-3",
                      },
                      [
                        _c("div", [_vm._v("Provider")]),
                        _c("div", [_vm._v("Amount")]),
                      ]
                    ),
                    _vm.topProvidersByBilling.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.topProvidersByBilling,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex justify-content-between align-items-center mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#38455D" } },
                                    [_vm._v(_vm._s(item.full_name_with_suffix))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#67B765",
                                        "font-weight": "500",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            parseFloat(
                                              item.count
                                            ).toLocaleString()
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "w-100 text-center" }, [
                          _vm._v("No Data"),
                        ]),
                  ]),
                  _c("b-card", { staticClass: "summary-card shadow mb-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-start pb-4",
                      },
                      [
                        _c("div", { staticClass: "summary-card-header-txt" }, [
                          _vm._v(" Top Procedures by Billing "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center small-head-txt-med mb-3",
                      },
                      [
                        _c("div", [_vm._v("Procedure")]),
                        _c("div", [_vm._v("Amount")]),
                      ]
                    ),
                    _vm.topProceduresByBilling.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.topProceduresByBilling,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex justify-content-between align-items-center mb-3",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#38455D" } },
                                    [_vm._v(_vm._s(item.short))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#139F92",
                                        "font-weight": "500",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            parseFloat(
                                              item.count
                                            ).toLocaleString()
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "w-100 text-center" }, [
                          _vm._v("No Data"),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }