var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.taxonomyCodes, function (code, index) {
        return _c(
          "b-badge",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { variant: "dark" },
                expression: "{ variant: 'dark' }",
                modifiers: { hover: true },
              },
            ],
            key: index,
            staticClass: "mr-2 p-2 mb-2 border border-1",
            attrs: {
              pill: "",
              href: "#!",
              variant: "light",
              title: "Code: " + code.code,
            },
            on: {
              click: function ($event) {
                return _vm.removePracticeTaxonomyCode(code)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.renderTaxonomyCode(code)) + " "),
            _vm.canEdit
              ? _c("b-icon", {
                  staticClass: "ml-1",
                  attrs: { icon: "x-circle-fill" },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.canEdit
        ? _c(
            "b-badge",
            {
              staticClass: "mr-2 border border-primary border-1 p-2 mt-1",
              attrs: { pill: "", href: "#!", variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showModal()
                },
              },
            },
            [
              _c("b-icon", {
                staticClass: "mr-2",
                attrs: { icon: "plus-circle-fill" },
              }),
              _vm._v(" Add Taxonomy Code "),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modalId,
            title: "Add Practice Taxonomy Code",
            "hide-footer": "",
            size: "m",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { id: "taxonomy_code", label: "New Taxonomy Code" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("taxonomy-code-multiselect", {
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mt-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-basic-btn",
                      on: {
                        click: function ($event) {
                          return _vm.hideModal()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit()
                        },
                      },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }