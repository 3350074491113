var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "div",
        [
          _c("report-list", {
            attrs: { id: _vm.practice.practice_id, idType: "practice" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }