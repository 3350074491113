var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "xl" } },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c("b-icon", {
                staticStyle: { cursor: "pointer" },
                attrs: { "font-scale": "2.5", icon: "x-circle" },
                on: { click: _vm.returnToPracticeList },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "avo-header-text",
              staticStyle: { "text-align": "center", "font-size": "28px" },
            },
            [_vm._v(" New Practice Group ")]
          ),
          _c(
            "div",
            {
              staticClass: "mt-4",
              staticStyle: { "text-align": "center", "margin-left": "-5px" },
            },
            [
              _c("hr", { staticClass: "circle-line" }),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-center",
                  staticStyle: {
                    "margin-top": "-34px",
                    "margin-right": "-15px",
                  },
                },
                [
                  _c("div", { staticClass: "d-flex-column mr-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-iconstack",
                          { staticStyle: { "margin-left": "-7px" } },
                          [
                            _c("b-icon", {
                              style:
                                _vm.step == 0 ? "color:gray" : "color:white",
                              attrs: {
                                icon: "circle-fill",
                                "font-scale": "1.5",
                              },
                            }),
                            _c("b-icon", {
                              attrs: { icon: "circle", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v(" Practice Info "),
                    ]),
                  ]),
                  _c("div", { staticClass: "d-flex-column ml-4 mr-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-iconstack",
                          { staticStyle: { "margin-left": "-7px" } },
                          [
                            _c("b-icon", {
                              style:
                                _vm.step == 1 ? "color:gray" : "color:white",
                              attrs: {
                                icon: "circle-fill",
                                "font-scale": "1.5",
                              },
                            }),
                            _c("b-icon", {
                              attrs: { icon: "circle", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-2" }, [_vm._v(" Locations ")]),
                  ]),
                  _c("div", { staticClass: "d-flex-column ml-4 mr-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-iconstack",
                          { staticStyle: { "margin-left": "-7px" } },
                          [
                            _c("b-icon", {
                              style:
                                _vm.step == 2 ? "color:gray" : "color:white",
                              attrs: {
                                icon: "circle-fill",
                                "font-scale": "1.5",
                              },
                            }),
                            _c("b-icon", {
                              attrs: { icon: "circle", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-2" }, [_vm._v(" Providers ")]),
                  ]),
                ]
              ),
            ]
          ),
          _c("hr"),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              [
                _c(
                  "b-alert",
                  {
                    staticStyle: { width: "750px" },
                    attrs: {
                      variant: _vm.alertError ? "danger" : "success",
                      dismissible: "",
                    },
                    model: {
                      value: _vm.showAlert,
                      callback: function ($$v) {
                        _vm.showAlert = $$v
                      },
                      expression: "showAlert",
                    },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
                ),
              ],
              1
            ),
          ]),
          _vm.step == 0
            ? _c(
                "b-container",
                { staticStyle: { width: "500px" } },
                [
                  _c("b-card", [
                    _c(
                      "div",
                      { staticClass: "avo-header-text mb-4" },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "pencil" },
                        }),
                        _vm._v(" Practice Info "),
                      ],
                      1
                    ),
                    _c("hr", {
                      staticStyle: {
                        "margin-right": "-20px",
                        "margin-left": "-20px",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "name", label: "Name" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.practice.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.practice, "name", $$v)
                                    },
                                    expression: "practice.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "group_npi", label: "Group NPI" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.practice.group_npi,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.practice, "group_npi", $$v)
                                    },
                                    expression: "practice.group_npi",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "tax_id", label: "Tax ID" } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.practice.tax_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.practice, "tax_id", $$v)
                                    },
                                    expression: "practice.tax_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: { click: _vm.submitPractice },
                        },
                        [
                          _c(
                            "b-overlay",
                            { attrs: { show: _vm.submitLoading } },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.step == 1
            ? _c(
                "b-container",
                { staticStyle: { width: "500px" } },
                [
                  _vm.locations.length == 0
                    ? _c(
                        "b-card",
                        { staticStyle: { "text-align": "center" } },
                        [_vm._v(" No locations created ")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.locations, function (location, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mb-2" },
                      [
                        _vm.locationIds[index] == null
                          ? _c("b-card", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avo-header-text" },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: { icon: "pencil" },
                                      }),
                                      _vm._v(" New Location "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("b-icon", {
                                        staticClass: "mt-2",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "trash",
                                          "font-scale": "1.3",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeLocationForm(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("hr", {
                                staticStyle: {
                                  "margin-left": "-20px",
                                  "margin-right": "-20px",
                                },
                              }),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "location_name",
                                        label: "Name",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            model: {
                                              value: location.name,
                                              callback: function ($$v) {
                                                _vm.$set(location, "name", $$v)
                                              },
                                              expression: "location.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "mr-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "fax", label: "Fax" } },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              model: {
                                                value: location.fax,
                                                callback: function ($$v) {
                                                  _vm.$set(location, "fax", $$v)
                                                },
                                                expression: "location.fax",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "telephone",
                                          label: "Phone",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              model: {
                                                value: location.telephone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    location,
                                                    "telephone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "location.telephone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "address_line1",
                                        label: "Street address",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            model: {
                                              value: location.address_line1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  location,
                                                  "address_line1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "location.address_line1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "mr-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "city", label: "City" } },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              model: {
                                                value: location.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    location,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression: "location.city",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mr-2 ml-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: { id: "state", label: "State" },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              model: {
                                                value: location.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    location,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression: "location.state",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { id: "zip", label: "Zip" } },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              model: {
                                                value: location.zip,
                                                callback: function ($$v) {
                                                  _vm.$set(location, "zip", $$v)
                                                },
                                                expression: "location.zip",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "ptan", label: "PTAN" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            model: {
                                              value: location.ptan,
                                              callback: function ($$v) {
                                                _vm.$set(location, "ptan", $$v)
                                              },
                                              expression: "location.ptan",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _c(
                                    "b-overlay",
                                    { attrs: { show: _vm.submitLoading } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitLocation(index)
                                            },
                                          },
                                        },
                                        [_vm._v("Create location")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("b-card", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "check2-circle",
                                          "font-scale": "1.3",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "avo-header-text ml-2" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(location.name) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("b-icon", {
                                        staticClass: "mt-2",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "trash",
                                          "font-scale": "1.3",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeLocationForm(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          staticStyle: { border: "0px!important" },
                          on: { click: _vm.addNewLocationForm },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "plus-circle" } }),
                          _vm._v(" Add "),
                          _vm.locations.length > 0
                            ? _c("span", [_vm._v(" another ")])
                            : _c("span", [_vm._v(" a ")]),
                          _vm._v(" location "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-basic-btn mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.previousStep()
                            },
                          },
                        },
                        [_vm._v("Back")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: {
                            click: function ($event) {
                              return _vm.submitLocationsSection()
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.step == 2
            ? _c(
                "b-container",
                { staticStyle: { width: "500px" } },
                [
                  _c("b-card", [
                    _c(
                      "div",
                      { staticClass: "avo-header-text" },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "search" },
                        }),
                        _vm._v(" Providers "),
                      ],
                      1
                    ),
                    _c("hr", {
                      staticStyle: {
                        "margin-right": "-20px",
                        "margin-left": "-20px",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "providers",
                              label: "Search providers",
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("provider-multiselect", {
                                  attrs: { multiple: true },
                                  model: {
                                    value: _vm.providers,
                                    callback: function ($$v) {
                                      _vm.providers = $$v
                                    },
                                    expression: "providers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.newProvider == null
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn",
                              staticStyle: { border: "0px!important" },
                              on: {
                                click: function ($event) {
                                  _vm.newProvider = Object.assign(
                                    {},
                                    _vm.newProviderTemplate
                                  )
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "plus-circle" },
                              }),
                              _vm._v("Add a new provider"),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("b-card", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "avo-header-text" },
                              [
                                _c("b-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "pencil" },
                                }),
                                _vm._v(" New Provider "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("b-icon", {
                                  staticClass: "mt-2",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { icon: "trash", "font-scale": "1.3" },
                                  on: {
                                    click: function ($event) {
                                      _vm.newProvider = null
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("hr", {
                          staticStyle: {
                            "margin-left": "-20px",
                            "margin-right": "-20px",
                          },
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "provider_given_name",
                                  label: "First name",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.given_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newProvider,
                                            "given_name",
                                            $$v
                                          )
                                        },
                                        expression: "newProvider.given_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "provider_family_name",
                                  label: "Last name",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.family_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newProvider,
                                            "family_name",
                                            $$v
                                          )
                                        },
                                        expression: "newProvider.family_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-4" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "provider_middle_name_or_initial",
                                    label: "Middle name/initial",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        model: {
                                          value:
                                            _vm.newProvider
                                              .middle_name_or_initial,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newProvider,
                                              "middle_name_or_initial",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newProvider.middle_name_or_initial",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "provider_suffix",
                                    label: "Suffix",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        model: {
                                          value: _vm.newProvider.suffix,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newProvider,
                                              "suffix",
                                              $$v
                                            )
                                          },
                                          expression: "newProvider.suffix",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "provider_type",
                                  label: "Billing type",
                                },
                              },
                              [
                                _c("b-form-select", {
                                  attrs: { options: _vm.providerTypes },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [_vm._v("Select type")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1663940744
                                  ),
                                  model: {
                                    value: _vm.newProvider.provider_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.newProvider,
                                        "provider_type",
                                        $$v
                                      )
                                    },
                                    expression: "newProvider.provider_type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "provider_npi", label: "NPI" } },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.npi,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.newProvider, "npi", $$v)
                                        },
                                        expression: "newProvider.npi",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "provider_ptan", label: "PTAN" } },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.ptan,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.newProvider, "ptan", $$v)
                                        },
                                        expression: "newProvider.ptan",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "telephone", label: "Phone" } },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.telephone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newProvider,
                                            "telephone",
                                            $$v
                                          )
                                        },
                                        expression: "newProvider.telephone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "email", label: "Email" } },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.newProvider.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newProvider,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "newProvider.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "right" } },
                          [
                            _c(
                              "b-overlay",
                              { attrs: { show: _vm.submitLoading } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "avo-primary-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitProvider()
                                      },
                                    },
                                  },
                                  [_vm._v("Create provider")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-basic-btn mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.previousStep()
                            },
                          },
                        },
                        [_vm._v("Back")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: {
                            click: function ($event) {
                              return _vm.associateProvidersWithPractice()
                            },
                          },
                        },
                        [_vm._v("Finish")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }