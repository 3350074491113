var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("div", { staticClass: "d-flex mb-4" }, [
          _c(
            "div",
            {
              staticClass: "avo-page-header-text",
              staticStyle: { "margin-right": "30px" },
            },
            [_vm._v(" Practices ")]
          ),
          _c(
            "div",
            [
              _c(
                "b-input-group",
                { staticClass: "search-group" },
                [
                  _c("b-input-group-prepend", [
                    _c(
                      "span",
                      { staticClass: "input-group-text" },
                      [_c("b-icon", { attrs: { icon: "search" } })],
                      1
                    ),
                  ]),
                  _c("b-form-input", {
                    staticClass: "practice-search-input pl-1",
                    attrs: {
                      type: "search",
                      placeholder: "Try typing a practice's name",
                    },
                    on: {
                      keyup: _vm.debouncePractices,
                      search: _vm.debouncePractices,
                    },
                    model: {
                      value: _vm.search_params.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.search_params, "search", $$v)
                      },
                      expression: "search_params.search",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c("span", { staticClass: "divider" }),
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "dropdown filter-dropdown",
                          attrs: { boundary: "window" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("b-icon-funnel", { staticClass: "mr-2" }),
                                  _vm._v(_vm._s(_vm.filterInputLabel) + " "),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown-form",
                            {
                              staticClass:
                                "d-flex flex-column align-items-start",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "20px",
                                    "font-weight": "300",
                                  },
                                },
                                [_vm._v("Filters")]
                              ),
                              _c("div", { staticClass: "selecter-label" }, [
                                _vm._v("Provider"),
                              ]),
                              _c("multiselect", {
                                attrs: {
                                  "hide-selected": true,
                                  options: _vm.providers,
                                  "track-by": "provider_id",
                                  label: "full_name_with_suffix",
                                  multiple: true,
                                  "show-labels": false,
                                  "close-on-select": false,
                                  placeholder: "-- Select an Option --",
                                },
                                model: {
                                  value: _vm.filters.providers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "providers", $$v)
                                  },
                                  expression: "filters.providers",
                                },
                              }),
                              _c("div", { staticClass: "selecter-label" }, [
                                _vm._v("Payer"),
                              ]),
                              _c("multiselect", {
                                attrs: {
                                  "hide-selected": true,
                                  options: _vm.payers,
                                  "track-by": "payer_id",
                                  label: "name",
                                  multiple: true,
                                  "show-labels": false,
                                  "close-on-select": false,
                                  placeholder: "-- Select an Option --",
                                },
                                model: {
                                  value: _vm.filters.payers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "payers", $$v)
                                  },
                                  expression: "filters.payers",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start mt-4",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-primary-btn mr-5",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.doSearch },
                                    },
                                    [_vm._v("Apply")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "avo-basic-btn",
                                      attrs: { variant: "outline-primary" },
                                      on: { click: _vm.clearFilters },
                                    },
                                    [_vm._v("Clear Filters")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-right": "20px", "padding-top": "10px" } },
          [
            _vm.canViewButtons()
              ? _c(
                  "b-button",
                  {
                    staticClass: "avo-primary-btn",
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.addPractice },
                  },
                  [_vm._v("Add Practice")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "b-overlay",
        {
          staticStyle: { "min-height": "200px" },
          attrs: { show: !_vm.loaded, variant: "white" },
        },
        [
          _vm.practices.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.practices, function (practice, index) {
                    return _c(
                      "b-row",
                      {
                        key: index,
                        staticClass: "practice-box",
                        on: {
                          click: function ($event) {
                            return _vm.selectPractice(index)
                          },
                        },
                      },
                      [
                        _c(
                          "b-col",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { cols: "4" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column",
                                style:
                                  "padding-left:10px; border-left:3px solid " +
                                  _vm.colors[index % 7],
                              },
                              [
                                _c("div", { staticClass: "avo-text-light" }, [
                                  _vm._v("Practice group"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "practice-name-text" },
                                  [_vm._v(" " + _vm._s(practice.name) + " ")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "d-flex flex-row",
                            staticStyle: {
                              "margin-top": "10px",
                              "justify-content": "center",
                            },
                            attrs: { cols: "4" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column",
                                staticStyle: { "margin-right": "20px" },
                              },
                              [
                                _c("div", { staticClass: "avo-text-light" }, [
                                  _vm._v("Locations"),
                                ]),
                                _c("div", { staticClass: "bold" }, [
                                  _vm._v(
                                    " " + _vm._s(practice.count_locations) + " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column",
                                staticStyle: { "margin-left": "20px" },
                              },
                              [
                                _c("div", { staticClass: "avo-text-light" }, [
                                  _vm._v("Providers"),
                                ]),
                                _c("div", { staticClass: "bold" }, [
                                  _vm._v(
                                    " " + _vm._s(practice.count_providers) + " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm.canViewButtons()
                          ? _c(
                              "b-col",
                              {
                                staticClass: "d-flex justify-content-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-right",
                                    staticStyle: { "margin-right": "20px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "avo-text-light" },
                                      [_vm._v("Unassigned Claims")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#41b0a5",
                                          "text-align": "right",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNumber(
                                                practice.count_unassigned_claims
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "avo-text-light",
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDollars(
                                                practice.total_dollar_unassigned_claims,
                                                false
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-column",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "avo-text-light" },
                                      [_vm._v("Unpaid Claims")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#eec05a",
                                          "text-align": "right",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNumber(
                                                practice.count_unpaid_claims
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "avo-text-light",
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDollars(
                                                practice.total_dollar_unpaid_claims,
                                                false
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "center",
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm.loaded
            ? _c(
                "div",
                {
                  staticClass: "avo-page-header-text",
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" No practices found ")]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }