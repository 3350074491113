var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "add-practice-provider" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "new-practice-provider",
            title: !_vm.new_provider
              ? "Add provider to practice"
              : "Create new provider",
            "hide-footer": "",
            size: "lg",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-nav",
            { staticClass: "mb-4 nav-section", attrs: { tabs: "" } },
            [
              _c(
                "b-nav-item",
                {
                  attrs: { active: !_vm.new_provider },
                  on: {
                    click: function ($event) {
                      return _vm.setNewProvider(false)
                    },
                  },
                },
                [_vm._v("Existing provider")]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.new_provider },
                  on: {
                    click: function ($event) {
                      return _vm.setNewProvider(true)
                    },
                  },
                },
                [_vm._v("New provider")]
              ),
            ],
            1
          ),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _vm.new_provider
                ? _c(
                    "div",
                    [
                      _c("provider-add", {
                        attrs: { "practice-id": _vm.practiceId },
                        on: {
                          associatedProviderWithPractice: function ($event) {
                            return _vm.hideNewProviderModal($event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "provider", label: "Provider:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("provider-multiselect", {
                                attrs: {
                                  multiple: false,
                                  "close-on-select": true,
                                },
                                model: {
                                  value: _vm.provider,
                                  callback: function ($$v) {
                                    _vm.provider = $$v
                                  },
                                  expression: "provider",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              !_vm.new_provider
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn",
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(" Add provider to practice ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }