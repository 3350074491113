var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { attrs: { fluid: "xl" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: { "margin-top": "14px" },
                      attrs: { cols: "6" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "information-card" },
                        [
                          _c(
                            "b-row",
                            { staticClass: "avo-header-text mb-4" },
                            [_c("b-col", [_vm._v(" Practice Information ")])],
                            1
                          ),
                          _c(
                            "b-row",
                            {
                              staticClass: "information-row",
                              staticStyle: { "margin-top": "20px" },
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Name ")]
                              ),
                              _c("b-col", [
                                _vm._v(" " + _vm._s(_vm.practice.name) + " "),
                              ]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Group NPI ")]
                              ),
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.practice.group_npi || "-") +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Tax ID ")]
                              ),
                              _c("b-col", [
                                _vm._v(
                                  " " + _vm._s(_vm.practice.tax_id || "-") + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Taxonomy Codes ")]
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("practice-taxonomy-codes", {
                                    attrs: {
                                      practice: _vm.practice,
                                      taxonomyCodes:
                                        _vm.practice.taxonomy_codes,
                                    },
                                    on: {
                                      submitted: function ($event) {
                                        return _vm.$emit("practiceUpdated")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Type ")]
                              ),
                              _vm.practice.practice_type != null
                                ? _c("b-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.practice_types[
                                            _vm.practice.practice_type
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("b-col", [_vm._v(" N/A ")]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Address ")]
                              ),
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.practice_address
                                        ? _vm.practice_address.full_address ||
                                            "-"
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "b-row",
                            { staticClass: "information-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "label-text",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v(" Billing Address ")]
                              ),
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.practice_billing_address
                                        ? _vm.practice_billing_address
                                            .full_address || "-"
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("document-list", {
                        attrs: {
                          idType: "practice",
                          id: _vm.practice.practice_id,
                          perPage: "6",
                          mode: "table",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }