var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: "PracticesList" } },
            [
              _vm.practiceSelected
                ? _c("practice-view", {
                    on: { updated: _vm.onPracticesUpdated },
                  })
                : _vm.addPractice
                ? _c("practice-add", {
                    on: { submitted: _vm.onPracticesUpdated },
                  })
                : _c("practices-list"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }