var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { variant: "danger", dismissible: "" },
                      model: {
                        value: _vm.showAlert,
                        callback: function ($$v) {
                          _vm.showAlert = $$v
                        },
                        expression: "showAlert",
                      },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.practice
            ? _c(
                "back-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.clearPractice()
                    },
                  },
                },
                [_vm._v("Back to Practices")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-4 ml-2 mr-4" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex flex-column", attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "avo-text-light" }, [
                    _vm._v("Practice Group"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "avo-header-text",
                      staticStyle: { "font-size": "36px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.practice.name) + " ")]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { cols: "4" },
                },
                [
                  _c("div", {
                    staticClass: "mt-2 mr-2",
                    staticStyle: {
                      "padding-left": "10px",
                      "border-left": "4px solid var(--blue)",
                      "margin-bottom": "13px",
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column mr-4" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "avo-header-text bold",
                          staticStyle: { "font-size": "28px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.practice.count_locations || 0) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", [_vm._v("Locations")]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "mt-2 ml-4 mr-2",
                    staticStyle: {
                      "padding-left": "10px",
                      "border-left": "4px solid #fd4739",
                      "margin-bottom": "13px",
                    },
                  }),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "avo-header-text bold",
                        staticStyle: { "font-size": "28px" },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.practice.count_providers || 0) + " "
                        ),
                      ]
                    ),
                    _c("div", [_vm._v("Providers")]),
                  ]),
                ]
              ),
              _vm.hasPermission("edit")
                ? _c("b-col", { staticClass: "d-flex justify-content-end" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.practice-edit",
                                modifiers: { "practice-edit": true },
                              },
                            ],
                            staticClass: "avo-primary-btn",
                            attrs: { disabled: _vm.editPracticeLoading },
                          },
                          [
                            _c(
                              "b-overlay",
                              {
                                attrs: {
                                  show: _vm.editPracticeLoading,
                                  variant: "white",
                                },
                              },
                              [_vm._v("Edit Practice")]
                            ),
                          ],
                          1
                        ),
                        _c("practice-edit", {
                          on: {
                            loaded: function ($event) {
                              _vm.editPracticeLoading = false
                            },
                            updated: _vm.onPracticeUpdated,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "b-nav",
                { staticClass: "nav-section", attrs: { tabs: "" } },
                [
                  _vm.hasPermission("summary_tab")
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: { active: _vm.activeTab == "PracticeSummary" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab("PracticeSummary")
                            },
                          },
                        },
                        [_vm._v("Summary")]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "PracticeDetails" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PracticeDetails")
                        },
                      },
                    },
                    [_vm._v("Details")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "PracticeLocations" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PracticeLocations")
                        },
                      },
                    },
                    [_vm._v("Locations")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "PracticeProviders" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PracticeProviders")
                        },
                      },
                    },
                    [_vm._v("Providers")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "PracticeFees" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PracticeFees")
                        },
                      },
                    },
                    [_vm._v("Fee Schedule")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        active: _vm.activeTab == "PracticeResourceLogins",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PracticeResourceLogins")
                        },
                      },
                    },
                    [_vm._v("Logins")]
                  ),
                  _vm.hasPermission("reports_tab")
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: { active: _vm.activeTab == "PracticeReports" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab("PracticeReports")
                            },
                          },
                        },
                        [_vm._v("Reports")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "keep-alive",
                [
                  _c(_vm.activeTab, {
                    tag: "component",
                    staticClass: "mt-4",
                    on: { practiceUpdated: _vm.refreshSelectedPracticeData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }