var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice
    ? _c(
        "div",
        [
          _c(
            "b-alert",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              staticStyle: { "margin-left": "20px" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "provider-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Search provider",
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                      _c("b-input-group-append", { attrs: { "is-text": "" } }, [
                        _c("span", { staticClass: "divider" }),
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { label: "Show only billing providers" },
                                model: {
                                  value: _vm.doFilterNonBillingProviders,
                                  callback: function ($$v) {
                                    _vm.doFilterNonBillingProviders = $$v
                                  },
                                  expression: "doFilterNonBillingProviders",
                                },
                              },
                              [_vm._v(" Show only billing providers ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-right": "20px" } },
                [
                  _vm.canEdit
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.new-practice-provider",
                              modifiers: { "new-practice-provider": true },
                            },
                          ],
                          staticClass: "avo-primary-btn",
                        },
                        [_vm._v("Add Provider")]
                      )
                    : _vm._e(),
                  _c("practice-provider-add", {
                    attrs: { "practice-id": _vm.practice_id },
                    on: {
                      submitted: function ($event) {
                        return _vm.getProviders()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("b-table", {
            ref: "providersTable",
            staticClass: "mt-4",
            attrs: {
              "filter-function": _vm.customFilterFunction,
              filter: _vm.searchObject,
              items: _vm.getProviders,
              "no-provider-paging": "",
              "no-provider-sorting": "",
              "no-provider-filtering": "",
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
              fields: _vm.fields,
              "sort-icon-left": "",
              "empty-filtered-text": "No providers match your search",
              "empty-text": "No providers found",
              "show-empty": "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u(
              [
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c("b-spinner", {
                            staticClass: "mr-2",
                            staticStyle: { width: "2rem", height: "2rem" },
                            attrs: { variant: "primary" },
                          }),
                          _vm._v(" Busy working ... "),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(taxonomy_codes)",
                  fn: function (data) {
                    return [
                      _c("provider-taxonomy-codes", {
                        attrs: {
                          provider: data.item,
                          "taxonomy-codes": data.item.taxonomy_codes || [],
                        },
                        on: {
                          submitted: function ($event) {
                            return _vm.refreshTable()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(provider_type)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.renderProviderType(data.item.provider_type)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(edit)",
                  fn: function (data) {
                    return [
                      _vm.canEdit
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value:
                                    "provider-edit-" + data.item.provider_id,
                                  expression:
                                    "'provider-edit-' + data.item.provider_id",
                                },
                              ],
                              staticClass: "avo-basic-btn",
                            },
                            [
                              _c("i", { staticClass: "fas fa-edit mr-2" }),
                              _vm._v("Edit"),
                            ]
                          )
                        : _vm._e(),
                      _c("provider-edit", {
                        attrs: { "selected-provider": data.item },
                        on: {
                          submitted: function ($event) {
                            return _vm.providerEditSubmittedCallback($event)
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              4294017440
            ),
          }),
          _c(
            "div",
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "center",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }