var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "practice-location-edit" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modal_id,
            title: "Edit Practice Location",
            "hide-footer": "",
            size: "xl",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "name", label: "Name:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.name,
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "ptan", label: "PTAN:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.ptan,
                                },
                                model: {
                                  value: _vm.form.ptan,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ptan", $$v)
                                  },
                                  expression: "form.ptan",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "telephone", label: "Phone:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.telephone,
                                },
                                model: {
                                  value: _vm.form.telephone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "telephone", $$v)
                                  },
                                  expression: "form.telephone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "fax", label: "Fax:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.fax,
                                },
                                model: {
                                  value: _vm.form.fax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fax", $$v)
                                  },
                                  expression: "form.fax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { id: "address_1", label: "Address Line 1:" },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder:
                                    _vm.selectedLocation.address_line1,
                                },
                                model: {
                                  value: _vm.form.address_line1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address_line1", $$v)
                                  },
                                  expression: "form.address_line1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { id: "address_2", label: "Address Line 2:" },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.address_2,
                                },
                                model: {
                                  value: _vm.form.address_line2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address_line2", $$v)
                                  },
                                  expression: "form.address_line2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "city", label: "City:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.city,
                                },
                                model: {
                                  value: _vm.form.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "city", $$v)
                                  },
                                  expression: "form.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "state", label: "State:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.state,
                                },
                                model: {
                                  value: _vm.form.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "state", $$v)
                                  },
                                  expression: "form.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "country", label: "Country:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.country,
                                },
                                model: {
                                  value: _vm.form.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "country", $$v)
                                  },
                                  expression: "form.country",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "zip", label: "Zip:" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.selectedLocation.zip,
                                },
                                model: {
                                  value: _vm.form.zip,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "zip", $$v)
                                  },
                                  expression: "form.zip",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-danger-btn",
                          on: { click: _vm.deletePracticeLocation },
                        },
                        [
                          _c("i", { staticClass: "fas fa-trash-alt mr-2" }),
                          _vm._v(" Delete Practice Location "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" Submit Changes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }