var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "update-practice-master-pass" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "update-practice-master-pass",
            title: "Manage Practice Master Pass",
            "hide-footer": "",
            size: "lg",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "password", label: "Password" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "confirmpassword",
                            label: "Confirm password",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { type: "password" },
                                model: {
                                  value: _vm.form.confirm_password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "confirm_password", $$v)
                                  },
                                  expression: "form.confirm_password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" Update Master Password ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }